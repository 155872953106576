.card {
  padding: 30px;
  flex-direction: row;
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  justify-content: center;
  align-items: center;

  ul {
    font-size: 0.8rem;
  }

  .left {
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    img {
      width: 60px;
      height: 60px;
      max-width: none;
    }
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;


  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
    flex-grow: 1;
  }

  hr {
    grid-column: span 2; /* Make the <hr> span across both columns */
    margin: 10px 0;
  }
}
