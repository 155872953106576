html,
body,
#root {
  height: 100%;
  width: 100%;
}

.WaveFunctionCollapse {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  table,
  tr,
  th,
  td,
  a {
    font-family: '', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 6px 13px;
    border: 1px solid rgb(209, 209, 209);
  }

  p {
    line-height: 1.5;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
    margin-top: 1.5em;
  }
  h3 {
    font-size: 1.3em;
    color: #545454;
    margin-top: 1em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.8em;
  }
  h6 {
    font-size: 0.7em;
  }

  p, li {
    font-size: 1em;
    font-weight: 400;
    color: #333;
  }

  hr {
    border: 0;
    height: 1px;
    background: #333;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.pixelated {
  image-rendering: -moz-crisp-edges; /* For Firefox */
  image-rendering: -o-crisp-edges; /* For Opera */
  image-rendering: -webkit-optimize-contrast; /* For Safari */
  image-rendering: crisp-edges;
  image-rendering: pixelated; /* For modern browsers */
}
