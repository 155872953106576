.header {
  min-height: 190px;
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .links {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: 600px) {
      margin-right: 0;
    }

    .text {
      display: none;
    }
  }
}

.profile_picture_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.profile_picture {
  max-width: 150px;
  max-height: 150px;
  border-radius: 100%;
  margin: 20px;
}
