@use "sass:map";
@use "@picocss/pico/scss/pico" as * with (
  $theme-color: "slate",
  $semantic-root-element: "#root",
  $enable-semantic-container: true,
  $enable-classes: false
);

#root {
  > main {
    display: flex;
    flex-direction: column;
    padding: 0px 25px;
    max-width: 800px; //map.get(map.get($breakpoints, "md"), "viewport");
    padding-top: 10vh;
    padding-bottom: 10vh;
    min-height: 100%;
  }
}

h2 {
  mark {
    background: linear-gradient(to right, #84266d, #6f3985, #5d409a, #43489d);
    background-clip: text;
    padding: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
h4 {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 5px;
}
h5 {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 400;
  color: #505050;
  margin-bottom: 5px;
}
h6 {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 400;
  color: #999999;
}

.section {
  margin-top: 50px;

  // opacity: 0;
  // transform: translateY(20vh);
  // visibility: hidden;
  // transition: opacity 500ms ease-out;
  // will-change: opacity, transform, visibility;
}
.section.is-visible {
  // opacity: 1;
  // transform: none;
  // visibility: visible;
}
.hgroup {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.no-underline {
  text-decoration: none;
}

.no-margin {
  margin: 0;
}

.col {
  display: flex;
  flex-direction: column;
}

.cards {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

img{
  border-radius: 10px;
}

ul {
  padding-left: 20px;
  margin: 0;
  font-size: 0.9em;
  font-weight: 400;
}
li {
  list-style-type: disc;
  list-style-position: outside;
}

// .center {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: --pico-header-background;
// }
