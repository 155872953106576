.vertical-cards {
  display: grid;
  grid-template-columns: 355px 355px;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }  

  article {
    margin: 0;
  }

  li {
    font-size: 0.8rem;
  }
}
